import React, { Component, useEffect, useState, useRef } from 'react';
import { graphql, Link } from 'gatsby';
import PropertyDetails from './components/PropertyDetails';
import CMSContent from '../../components/CMSContent';
import PropertyAvailability from '../../components/PropertyAvailability';
import VirtualTour from '../../components/VirtualTour';
import Media from '../../components/Media';
import LightBox from '../../components/LightBox';
import PropertyReviews from '../../components/PropertyReviews';
import Layout from '../../layouts/JumpMenuLayout';
import Loadable from '@loadable/component';
import Cookies from 'js-cookie';
import PropertyDetailsList from '../../components/PropertyDetailsList';
import RandomImageGroup from '../../components/RandomImageGroup';
import SinglePropertyAvailabilityCalendar from '../../components/SinglePropertyAvailabilityCalendar';
// import { addDays } from 'date-fns';
// import DaterangePicker from './components/DaterangePicker'; //Loadable(() => import('./components/DaterangePicker')); // code-splitted, on demand loaded component
const Map = Loadable(() => import('../../components/Map')); // code-splitted, on demand loaded component

// import Map from '../../components/Map';
// import RequestInfoForm from '../../components/RequestInfoForm';
const RequestInfoForm = Loadable(() =>
  import('../../components/RequestInfoForm')
); // code-splitted, on demand loaded component
import Modal from '../../components/Modal';
import Loading, { Dots } from '../../components/Loading';
import SEO from '../../components/seo';
import styles from './styles.module.scss';
import Calendar from '../../components/Calendar';
import FeaturedFilesSlider from './components/FeaturedFilesSlider';
import { withApollo } from 'react-apollo';

import VRBO from '../../images/logo-vrbo.png';
import HomeAway from '../../images/logo-homeaway.png';
import gql from 'graphql-tag';
// import { DateRangePicker } from 'react-daterange-picker';
// import Moment from 'moment';
// import { extendMoment } from 'moment-range';

// const moment = extendMoment(Moment);

import { Query } from 'react-apollo';
import FAQEntryList from '../../components/FAQEntryList';
import Gallery from '../../components/Gallery';

import BlockSelectList from '../../components/BlockSelectList';
import './styles.scss';
// import 'react-date-range/dist/styles.css'; // main style file
// import 'react-date-range/dist/theme/default.css'; // theme css file

function ClientOnly({ children }) {
  // https://www.joshwcomeau.com/react/the-perils-of-rehydration/
  const [hasMounted, setHasMounted] = React.useState(false);
  React.useEffect(() => {
    setHasMounted(true);
  }, []);
  if (!hasMounted) {
    return null;
  }
  return <>{children}</>;
}

// const CALENDAR_DATA = gql`
//   query CalendarData($id: Int) {
//     property(where: { id: $id }) {
//       # name
//       # key
//       calendar {
//         start
//         end
//         availability
//         minStay
//         minStayThresholdAlert
//       }
//     }
//   }
// `;

const getVrboUrl = property => {
  const arr = (
    property.propertyListings.find(
      listing => listing.platform.key === 'homeaway'
    ) || []
  ).propertyUrl.split('/');
  return `https://www.vrbo.com/${arr[arr.length - 1]}`;
};

const SingleProperty = props => {
  const { pageContext, data, location, search } = props;

  const { sitePage, accompanyingMapImage } = data.bhip;

  const hasGuestBook = sitePage.cmsContent.html.match(/id="guestbook"/);
  const hasGuestMemories = sitePage.cmsContent.html.match(/id="guestmemories"/);
  let hasPhotoGallery = sitePage.cmsContent.html.match(/id="gallery"/);
  const property = data?.bhip?.property || {};
  const guestReservationReviewConnection =
    data?.bhip?.guestReservationReviewConnection || {};

  //modal=requestinfo&datepreference=20220503-20220506
  const params = new URLSearchParams(location?.search);
  // console.log('location', location)
  const onlinebooking = params.get('onlinebooking') == 'true' ? 'true' : Cookies.get('onlinebooking');
  const promo = params.get('promo') ? params.get('promo') : Cookies.get('promo');

  const [allowBooking, setAllowBooking] = useState(
    (params.get('preview') == 'true' || onlinebooking == 'true') ? true : false
  );

  const [requestInfoModalIsOpen, setRequestInfoModalIsOpen] = useState(
    params.get('modal') == 'requestinfo' ? true : false
  );
  const [moreOptionsModalIsOpen, setMoreOptionsModalIsOpen] = useState(false);

  const checkIn = params.get('checkIn');
  const checkOut = params.get('checkOut');
  const platform = params.get('platform');
  const view = allowBooking ? 'full' : (params.get('view') || null);
  const comboPropertyParam = params.get('property');
  const faqEntries = sitePage?.faqEntries || null;
  const isCombo = property?.type === 'COMBO' || false;
  const gradientContainer = useRef(null);
  const getDropdownMenuForCombo = () => {
    const menuItems = childProperties.reduce((acc, property) => {
      acc.push({
        name: property.name,
        key: property.key,
        slug: property?.sitePage?.slug,
      });
      return acc;
    }, []);
    if (menuItems.length === 0) {
      return null;
    }
    return menuItems;
  };
  const childProperties = property?.childProperties || [];
  const childPropertyHighlightsCollection = (
    property?.childProperties || []
  ).reduce((acc, property) => {
    if (!acc.files) {
      acc = property.highlights;
    } else {
      acc.files = acc.files.concat(property.highlights.files);

      //acc.files = acc.files.push(property.highlights.files);
    }
    // acc = Object.assign({}, acc, property.highlights);
    return acc;
  }, {});

  if (childPropertyHighlightsCollection.files) {
    hasPhotoGallery = true;
  }
  // let jumpMenuOptions = [
  //   {
  //     name: 'Overview',
  //     key: 'overview',
  //   },
  //   {
  //     name: 'Availability',
  //     key: `availability`,
  //   },
  //   // {
  //   //   name: '3D Tour',
  //   //   key: 'tour',
  //   //   dropdownMenu: isCombo ? getDropdownMenuForCombo() : null,
  //   //   hide: view !== 'full',
  //   // },
  //   // {
  //   //   name: 'Floor Plan',
  //   //   key: 'floorplan',
  //   //   dropdownMenu: isCombo ? getDropdownMenuForCombo() : null,
  //   //   hide: view !== 'full',
  //   // },
  //   {
  //     name: 'Photo Gallery',
  //     key: 'gallery',
  //   },

  //   {
  //     name: 'Map',
  //     key: 'map',
  //   },
  //   // {
  //   //   name: 'FAQ',
  //   //   key: 'faq',
  //   // },
  // ];

  // if (view === 'full') {
  //   const pos = jumpMenuOptions
  //     .map(function(e) {
  //       return e.key;
  //     })
  //     .indexOf('availability');
  //   jumpMenuOptions = [
  //     ...jumpMenuOptions.slice(0, pos + 1),
  //     {
  //       name: '3D Tour',
  //       key: 'tour',
  //       dropdownMenu: isCombo ? getDropdownMenuForCombo() : null,
  //     },
  //     {
  //       name: 'Floor Plan',
  //       key: 'floorplan',
  //       dropdownMenu: isCombo ? getDropdownMenuForCombo() : null,
  //     },
  //     ...jumpMenuOptions.slice(pos + 1),
  //   ];
  // }

  // if (hasGuestBook) {
  //   const pos = jumpMenuOptions
  //     .map(function(e) {
  //       return e.key;
  //     })
  //     .indexOf('map');
  //   jumpMenuOptions = [
  //     ...jumpMenuOptions.slice(0, pos),
  //     { name: 'Guest Book', key: 'guestbook' },
  //     ...jumpMenuOptions.slice(pos),
  //   ];
  // }
  // if (faqEntries && faqEntries[0]) {
  //   const pos = jumpMenuOptions
  //     .map(function(e) {
  //       return e.key;
  //     })
  //     .indexOf('faq');
  //   jumpMenuOptions = [
  //     ...jumpMenuOptions.slice(0, pos),
  //     ...jumpMenuOptions.slice(pos),
  //     { name: 'FAQ', key: 'faq' },
  //   ];
  // }

  const [jumpMenuOptions, setJumpMenuOptions] = useState([
    {
      name: 'Overview',
      key: 'overview',
    },
    {
      name: 'Availability',
      key: `availability`,
    },
    {
      name: '3D Tour',
      key: 'tour',
      dropdownMenu: isCombo ? getDropdownMenuForCombo() : null,
      hide: view !== 'full',
    },
    {
      name: 'Floor Plan',
      key: 'floorplan',
      dropdownMenu: isCombo ? getDropdownMenuForCombo() : null,
      hide: view !== 'full',
    },
    {
      name: 'Photo Gallery',
      key: 'gallery',
      hide: !hasPhotoGallery,
    },
    {
      name: 'Homes',
      key: 'homes',
      hide: !isCombo,
    },
    { name: 'Guest Book', key: 'guestbook', hide: !hasGuestBook },
    { name: 'Guest Memories', key: 'guestmemories', hide: !hasGuestMemories },
    {
      name: 'Testimonials',
      key: 'guesttestimonials',
      hide: guestReservationReviewConnection.aggregate.count < 6,
    },
    {
      name: 'Map',
      key: 'map',
    },
    {
      name: 'FAQ',
      key: 'faq',
      hide: !faqEntries ? true : false,
    },
  ]);

  const childPropertiesWith3DTours = isCombo
    ? property?.childProperties.reduce((acc, child) => {
      if (child?.virtualTours[0]) {
        acc.push(child);
      }
      return acc;
    }, [])
    : null;
  const comboPropertyWith3DToursForChildren = {
    ...property,
    childProperties: childPropertiesWith3DTours,
  };

  const shouldHideOnFullView = key => {
    switch (key) {
      case 'floorplan': {
        return property?.floorPlan ? false : true;
      }
      case 'tour': {
        if (isCombo || childPropertiesWith3DTours?.[0]) {
          return false;
        }
        return property?.virtualTours[0] ? false : true;
      }
      default:
        return false;
    }
  };

  useEffect(() => {
    if (view === 'full') {
      const jumpMenuWithFullView = jumpMenuOptions.reduce((acc, option) => {
        if (option.key === 'floorPlan' || option.key === 'tour') {
          acc.push({ ...option, hide: shouldHideOnFullView(option?.key) });
        } else {
          acc.push(option);
        }
        return acc;
      }, []);

      setJumpMenuOptions([...jumpMenuWithFullView]);
    }
    if (window.location.hash) {
      setTimeout(() => {
        window.smoothJumpMenus.scrollToCurrent();
      }, 500);
    }
    var showPopover = function () {
      $(this).popover('toggle');
    },
      hidePopover = function () {
        $(this).popover('hide');
      };
    $('.popover-dismiss')
      .popover({
        trigger: 'manual',
      })
      .click(showPopover)
      .blur(hidePopover);
    const mapObserver = new IntersectionObserver(
      (entries, imgObserver) => {
        if (entries[0].isIntersecting) {
          const mapContainer = document.querySelector('#single-property-map');
          const mapLoadingContainer = document.querySelector(
            '#map-loading-container'
          );

          if ($(mapContainer).hasClass('d-none')) {
            $(mapContainer).removeClass('d-none');
            $(mapContainer).addClass('d-block');
            $(mapLoadingContainer).addClass('d-none');
            $(mapLoadingContainer).removeClass('d-table');
          }

          //   const lazyImage = entry.target;
          //   if (lazyImage.dataset.iframeSrc) {
          //     loadiFrame(lazyImage);
          //   } else {
          //     loadFullImage(lazyImage);
          //   }
          //   imgObserver.unobserve(lazyImage);
        }
      },
      { rootMargin: '200px 0px' }
    );
    const map = document.querySelector('#map-loading-container');

    map && mapObserver.observe(map);
    // const faqPTags = $('#faq-content').find('p');
    // faqPTags.each((index, paragraph) => {
    //   $(paragraph).addClass('mb-2');
    // });
    return () => {
      $('.popover-dismiss').popover('dispose');
    };
  }, []);

  const getPlatformAvailabilityURLSuffix = key => {
    let suffix = '';
    if (checkIn && checkOut) {
      switch (key) {
        case 'vrbo':
          suffix = `?arrival=${checkIn}&departure=${checkOut}`;
          break;
        case 'homeaway':
          suffix = `?arrival=${checkIn}&departure=${checkOut}`;
          break;
        case 'airbnb':
          suffix = `?check_in=${checkIn}&check_out=${checkOut}`;
          break;
        case 'bookingcom':
          suffix = `?checkin=${checkIn}&checkout=${checkOut}`;
          break;
      }
    }
    return suffix;
  };
  const getPlatformButton = (key, Image) => {
    const platform =
      property.propertyListings.find(listing => listing.platform.key === key) ||
      {};

    if (key === 'vrbo') {
      const platform =
        property.propertyListings.find(
          listing => listing.platform.key === 'homeaway'
        ) || {};

      if (platform.propertyUrl && platform.propertyUrl !== '') {
        return (
          <div className="col col-12 col-lg-4">
            <a
              className={`${styles.modalButton} ${styles.platformButton
                } btn btn-outline-primary w-100 mb-2 rounded`}
              target="_blank"
              href={
                getVrboUrl(property) + getPlatformAvailabilityURLSuffix(key)
              }
            >
              <img src={VRBO} alt="VRBO" />
            </a>
          </div>
        );
      } else return null;
    }

    if (platform.propertyUrl && platform.propertyUrl !== '') {
      return (
        <div className="col col-12 col-lg-4">
          <a
            className={`${styles.modalButton} ${styles.platformButton
              } btn btn-outline-primary w-100 mb-2 rounded `}
            target="_blank"
            href={platform.propertyUrl + getPlatformAvailabilityURLSuffix(key)}
          >
            <img src={Image} />
          </a>
        </div>
      );
    }
    return null;
  };

  const files = sitePage?.featuredFileCollection?.files || [];
  const date = new Date();
  // const condensedExerpt = getCondensedExcerpt();

  // date.setDate(date.getDate() - 7);

  const [viewCollapseText, setViewCollapseText] = useState('View more');
  const [scrollPosition, setScrollPosition] = useState(null);

  const nodisc = params.get('nodisc') == '1' ? 1 : Cookies.get('nodisc');

  //console.log('DaterangePicker', DaterangePicker);
  return (
    <>
      <Layout
        jumpMenuOptions={jumpMenuOptions}
        propertyStickyHeader={true}
        searchbarLabel={sitePage?.title || property.name}
        customBottomPosition={true}
        showPropertyLegalText={nodisc == 1 ? false : true}
      >
        {/* <DaterangePicker propertyKey={property?.key} /> */}
        <SEO
          title={sitePage.seoTitle}
          description={sitePage.seoDescription}
          headTags={sitePage.headTags}
          featuredImage={sitePage.featuredImage}
        >
          <link
            rel="canonical"
            href={`https://www.beachhousesinparadise.com/${sitePage.slug}`}
          />
        </SEO>

        <div className={`container`}>
          {/* <DateRange
            ranges={ranges}
            moveRangeOnFirstSelection={true}
            onChange={item => setRanges([item.selection])}
            // disabledDates={['2023-08-29']}
            //showSelectionPreview={true}
            // showDateDisplay={false}
            direction="horizontal"
            // fixedHeight={true}
            months={2}
          /> */}
          <div className="d-block d-lg-flex justify-content-between">
            <article className={`${styles.leftColumn} flex-grow-1`}>
              {files?.length > 0 && (
                <div className="no-gutters-sm-down" id="overview">
                  <FeaturedFilesSlider
                    today={date}
                    useReducedFiles={true}
                    featuredFiles={files}
                    today={new Date()}
                  />
                </div>
              )}

              <div>
                <div className="position-relative mb-0">
                  <span>
                    {sitePage?.excerpt && (
                      <CMSContent blockData={sitePage.cmsContent.blockData}>
                        {sitePage?.excerpt}
                      </CMSContent>
                    )}
                  </span>
                  {sitePage?.excerpt_extended && (
                    <>
                      <div
                        className="mt-3"
                        id={styles.extendedExcerptContainer}
                      >
                        <CMSContent blockData={sitePage.cmsContent.blockData}>
                          {sitePage?.excerpt_extended}
                        </CMSContent>
                      </div>

                      <div
                        ref={gradientContainer}
                        className={`${styles.textGradient
                          } position-absolute w-100 ${styles.exerptCollapseContainer
                          } `}
                      />
                    </>
                  )}
                </div>

                {sitePage?.excerpt_extended && (
                  <div className="mb-0">
                    <a
                      onClick={() => {
                        $(gradientContainer.current).toggleClass('d-none');

                        const containerHeight = $(
                          `#${styles.extendedExcerptContainer}`
                        ).height();

                        $(gradientContainer.current).toggleClass(
                          styles.textGradient
                        );
                        $(gradientContainer.current).hasClass(
                          styles.textGradient
                        )
                          ? setViewCollapseText('View more')
                          : setViewCollapseText('View less');
                        $(`#${styles.extendedExcerptContainer}`).toggleClass(
                          `${styles.viewMore} mb-3`
                        );

                        if (containerHeight > 100) {
                          const scrollTop = $(window).scrollTop();
                          $(window).scrollTop(scrollPosition);
                        }

                        if (viewCollapseText === 'View more') {
                          const scrollTop = $(window).scrollTop();
                          setScrollPosition(scrollTop);
                        }
                      }}
                      className="btn btn-link text-primary pl-0"
                      style={{ "cursor": "pointer" }}
                      data-toggle="collapse"
                      // href="#exerpt-collapse-container"
                      // href="javascript:void(0);"
                      role="button"
                      aria-expanded="false"
                      aria-controls="exerpt-collapse-container"
                      data-target="#exerpt-collapse-container"
                    >
                      {viewCollapseText}
                    </a>
                  </div>
                )}
              </div>

              <div className="d-block d-lg-none">
                <PropertyDetails property={property} />
              </div>
              <div id={`availability`} className="mb-2">
                {/* <div
                  dangerouslySetInnerHTML={{ __html: sitePage.content }}
                /> */}
                {allowBooking ? <><div className="w-100 w-xl-50 d-flex justify-content-center mt-3  align-items-center ">
                  <PropertyAvailability
                    promo={promo}
                    propertyKey={property.key}
                    allowBooking={allowBooking}
                    context="button"
                    autoOpenEnabled={true}
                  /></div></> : <>
                  <div

                    className={`${styles.reserveDatesWrapper
                      } d-block d-xl-flex align-items-center _justify-content-center mt-3 mb-2`}
                  >
                    <div className="w-50">
                      <h2 className="my-0 mr-2">
                        Check Availability
                      </h2>
                    </div>
                    {allowBooking && <div className="w-100 w-xl-50 d-flex  mx-auto align-items-center ">
                      <PropertyAvailability
                        promo={promo}
                        propertyKey={property.key}
                        allowBooking={allowBooking}
                      /></div>}
                  </div>
                  <div /*className="aspect single-property-aspect-ratio-container"*/
                  >
                    <SinglePropertyAvailabilityCalendar
                      propertyId={property?.id}
                      allowBooking={allowBooking}
                      promo={promo}
                    />
                  </div>
                  <span className="mt-2">Are your dates unavailable?</span>{' '}
                  <Link
                    className="text-primary"
                    to="/rentals?checkavailability=true"
                  >
                    {allowBooking ? <span>Other Booking Options:</span> : <span>How to Book</span>}
                  </Link>
                  <span> at our other beach homes.</span>
                </>}

                {!allowBooking && <>
                  <h2 className="mt-3">How to Book</h2>
                  {Cookies.get('platform') || platform === 'homeaway' ? (
                    <div>
                      <p className="mt-3 mb-2">
                        "{property.name}" is {allowBooking && 'also'} available on all major travel
                        websites and vacation rental booking platforms. For
                        pricing and to confirm availability visit your favorite
                        travel site, including:
                      </p>

                      <div className="container px-0">
                        <div className="row">
                          {getPlatformButton('vrbo', VRBO)}
                          {getPlatformButton('homeaway', HomeAway)}
                          {/* {getPlatformButton('airbnb', AirBnb)}
                        {getPlatformButton('bookingcom', Booking)}
                        {getPlatformButton('tripadvisor', TripAdvisor)} */}
                          <div className="col col-12 col-lg-4">
                            {property.additionalMessaging && (
                              <button
                                type="link"
                                className={`${styles.platformButton
                                  } btn-link btn btn-outline-primary text-primary w-100 mb-2 font-weight-bold rounded`}
                                onClick={() => setMoreOptionsModalIsOpen(true)}
                              >
                                More Options
                              </button>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  ) : (
                    <p>{allowBooking ? property.additionalMessaging.replace(/is available/, 'is also available') : property.additionalMessaging}</p>
                  )}
                </>}
                {/* <p className="mt-3 mb-2">
                  "{property.name}" is available on all major travel websites
                  and vacation rental booking platforms. For pricing and to
                  confirm availability visit your favorite travel site,
                  including:
                </p>
                <div className="container px-0">
                  <div className="row">
                    {getPlatformButton('vrbo', VRBO)}
                    {getPlatformButton('homeaway', HomeAway)}
                    {getPlatformButton('airbnb', AirBnb)}
                    {getPlatformButton('bookingcom', Booking)}
                    {getPlatformButton('tripadvisor', TripAdvisor)}
                    <div className="col col-12 col-lg-4">
                      {property.additionalMessaging && (
                        <button
                          type="link"
                          className={`${styles.platformButton} btn-link btn btn-outline-primary text-primary w-100 mb-2 font-weight-bold rounded`}
                          onClick={() => setMoreOptionsModalIsOpen(true)}
                        >
                          More Options
                        </button>
                      )}
                    </div>
                  </div>
                </div> */}
                {!allowBooking && <div>
                  <div className="row">
                    <div className="col col-12">
                      <p className="mb-1plus">Need help? Questions?</p>
                    </div>

                    <div className="col col-12 col-lg-6 col-xl-3">
                      <button
                        id="request-info-button"
                        onClick={() => setRequestInfoModalIsOpen(true)}
                        className="btn btn-outline-primary mb-1plus font-weight-bold w-100"
                      >
                        Click here
                      </button>
                    </div>
                  </div>


                </div>
                }
                {property?.virtualTours[0] &&
                  property?.type === 'STANDARD' &&
                  view === 'full' && (
                    <ClientOnly>
                      <h2 id="tour">3D Tour</h2>
                      <VirtualTour
                        propertyVirtualTour={property?.virtualTours[0]}
                        thumbnailVersion="large"
                        withIcon={true}
                      />
                    </ClientOnly>
                  )}
                {/* <img
                  src={
                    property?.floorPlan.fileInstance?.file?.versions?.[0]
                      ?.fullUrl
                  }
                />
                {console.log(
                  'property?.floorPlan.fileInstance?.file',
                  property?.floorPlan.fileInstance?.id
                )} */}
                {property?.floorPlan &&
                  property?.type === 'STANDARD' &&
                  view === 'full' && (
                    <ClientOnly>
                      <h2 id="floorplan">Floor Plan</h2>
                      <Media
                        siteFileInstance={property?.floorPlan.fileInstance}
                      />
                    </ClientOnly>
                  )}
                {property?.virtualTours && isCombo && view === 'full' && (
                  <ClientOnly>
                    <div className="property-combo-select-list-wrapper">
                      <BlockSelectList
                        renderHeader={() => (
                          <h2 className="my-0 mr-2" id="tour">
                            3D Tour
                          </h2>
                        )}
                        defaultLabel={'Additional Combo 3D Tours'}
                        property={comboPropertyWith3DToursForChildren}
                        blockType="virtualTour"
                      />
                    </div>
                  </ClientOnly>
                )}
                {isCombo && view === 'full' && (
                  <ClientOnly>
                    <div className="property-combo-select-list-wrapper">
                      <BlockSelectList
                        renderHeader={() => (
                          <h2 className="my-0 mr-2" id="floorplan">
                            Floor Plan
                          </h2>
                        )}
                        defaultLabel={'Additional Floor Plans'}
                        property={property}
                        blockType="image"
                      />
                    </div>
                  </ClientOnly>
                )}
                <CMSContent blockData={sitePage.cmsContent.blockData}>
                  {sitePage.cmsContent.html}
                </CMSContent>
                {/* {childProperties && (
                  <div>
                    {childProperties?.map((property, index) => {
                      const anchor = {};
                      if (index === 0) {
                        anchor.id = 'gallery';
                      }
                      return (
                        <div key={property?.id}>
                          <h2 {...anchor}>
                            <Link
                              to={`/${property?.sitePage?.slug}`}
                              className="text-primary mr-1plus"
                            >
                              {property?.name}
                            </Link>
                            Highlights
                            <Link
                              className="ml-1plus"
                              to={`/${property?.sitePage?.slug}#gallery`}
                            >
                              (View All Photos)
                            </Link>
                          </h2>
                          <Gallery siteFileCollection={property?.highlights} />
                        </div>
                      );
                    })}
                  </div>
                )} */}
                {childProperties.length > 0 && (
                  <div>
                    <h2 id="homes">Homes</h2>
                    <div className="row p-0 px-lg-2">
                      {childProperties.map(property => {
                        return (
                          <div
                            className="mx-auto mx-lg-0 mb-3 col-lg-6  p-0 p-lg-0plus"
                            key={property.id}
                          >
                            <div /*className="no-gutters-sm-down"*/>
                              <Link
                                to={`/${property.sitePage.slug}`}
                                className="aspect ratio-4x3 mb-2"
                              >
                                <RandomImageGroup
                                  files={
                                    property.sitePage.featuredImageCollection
                                      .files
                                  }
                                  alt={`${property.name
                                    } vacation rental property`}
                                />
                                {property.status === 'COMING_SOON' && (
                                  <span className="thumbnail-label ">
                                    Coming Soon
                                  </span>
                                )}
                              </Link>
                            </div>
                            <div className="px-2 px-sm-0">
                              <Link
                                to={`/${property.sitePage.slug}`}
                                className="h2 font-weight-bold"
                              >
                                {property.name}
                                {property.status === 'NEW' && (
                                  <span className="ml-1">
                                    (
                                    <span
                                      className={`${styles.newPropertyText}`}
                                    >
                                      New
                                    </span>
                                    )
                                  </span>
                                )}
                              </Link>

                              <div className="mt-1plus">
                                <PropertyDetailsList property={property} />
                              </div>
                            </div>
                          </div>
                        );
                      })}
                    </div>
                  </div>
                )}
                <PropertyReviews propertyId={property.id} />
                {accompanyingMapImage && (
                  <>
                    {/* <!-- bhip:image {"siteFileInstanceId": 9811 } --><!-- /bhip:image --> */}
                    <div>
                      <Media siteFileInstance={accompanyingMapImage} />
                    </div>
                  </>
                )}
                <h2 id="map">Map</h2>
                <div
                  style={{ height: '500px' }}
                  id="map-loading-container"
                  className="d-table align-middle w-100"
                >
                  <div className="d-table-cell align-middle">
                    <Dots />
                  </div>
                </div>
                <div className="d-none" id="single-property-map">
                  <Map marker={property} />
                </div>
              </div>
              {faqEntries && faqEntries[0] && (
                <div id="faq-content">
                  <h2 id="faq" className="mb-2">
                    FAQ
                  </h2>
                  <FAQEntryList
                    faqEntries={sitePage.faqEntries}
                    className=""
                    header={false}
                  />
                </div>
              )}
            </article>
            <aside className={`${styles.rightColumn} ml-3`}>
              <div className="sticky-column" style={{ top: '70px' }}>
                <div className="pb-2 d-none d-lg-flex justify-content-between align-items-center border-bottom">
                  <PropertyAvailability
                    promo={promo}
                    propertyKey={property.key}
                    allowBooking={allowBooking}
                  />
                </div>
                <div className="d-none d-lg-block">
                  <PropertyDetails hasBorders={true} property={property} />
                </div>
              </div>
            </aside>
          </div>
          <Modal
            id="request-info"
            isOpen={requestInfoModalIsOpen}
            disableFocusTrap={true}
            title={'Request Info'}
            onClose={() => setRequestInfoModalIsOpen(false)}
          >
            <p>
              Ask us about availability, pricing, and more for your next
              vacation.
            </p>
            <p className="mb-2">
              Fields marked with an <span className="text-danger">*</span> are
              required
            </p>
            <RequestInfoForm targetPropertyId={property.id} />
          </Modal>
          <Modal
            isOpen={moreOptionsModalIsOpen}
            title={'More Options'}
            disableFocusTrap={true}
            onClose={() => setMoreOptionsModalIsOpen(false)}
          >
            <p>{property.additionalMessaging}</p>
          </Modal>
        </div>
      </Layout >
      <div
        style={{ bottom: '0px' }}
        className={`${styles.stickyFooter
          } w-100 position-sticky d-flex justify-content-between align-items-center d-lg-none bg-white pl-2`}
      >
        <PropertyAvailability
          promo={promo}
          propertyKey={property.key}
          allowBooking={allowBooking}
        />
      </div>
    </>
  );
};

// export const queryString = `
// query PropertyById($propertyForeignKey: Int) {
//   bhip {
//     property(where: { id: $propertyForeignKey }) {
//       id
//       additionalMessaging
//       priceOffSeason
//       label
//       key
//       name
//       description
//       sleeps
//       bedrooms
//       bathrooms
//       type
//       latitude
//       longitude
//       group
//       streetAddress1
//       virtualTours {
//         property {
//           name
//         }
//         url
//         additionalMessaging
//         thumbnailImage {
//           alt
//           destination
//           caption
//           file {
//             versions {
//               key
//               fullUrl
//               height
//               width
//             }
//           }
//         }
//       }
//       propertyListings {
//         propertyUrl
//         platform {
//           name
//           key
//         }
//       }
//       amenities {
//         name
//         key
//       }
//     }
//   }
// }
// `;

export const query = graphql`
  query PropertyPageById(
    $slug: String
    $propertyForeignKey: Int
    $accompanyingMapPhotoId: Int
  ) {
    bhip {
      accompanyingMapImage: siteFileInstance(
        where: { id: $accompanyingMapPhotoId }
      ) {
        alt
        caption
        destination
        file {
          mimetype
          fullUrl
          width
          height
          versions {
            fullUrl
            key
            width
            height
            mimetype
          }
        }
      }
      sitePage(where: { slug: $slug }) {
        title
        excerpt
        excerpt_extended
        slug
        cmsContent {
          html
          blockData {
            key
            data
          }
        }
        type
        status
        seoTitle
        seoDescription
        headTags
        references {
          type
          foreignKey
        }
        publishDate
        featuredImage {
          alt
          file {
            fullUrl
            width
            height
            versions {
              key
              width
              height
              fullUrl
              mimetype
            }
          }
        }
        featuredImageSecondary {
          alt
          file {
            versions {
              key
              fullUrl
              mimetype
            }
          }
        }
        featuredFileCollection {
          files {
            alt
            mimetype
            fullUrl
            width
            height
            versions {
              key
              fullUrl
              width
              height
              mimetype
            }
          }
        }
        faqEntries {
          id
          question
          answer
          sort
        }
        tags {
          key
          tagGroup {
            key
          }
        }
      }
      guestReservationReviewConnection(
        where: { propertyId: $propertyForeignKey }
      ) {
        aggregate {
          count
        }
      }
      property(where: { id: $propertyForeignKey }) {
        id
        additionalMessaging
        priceOffSeason
        label
        key
        name
        description
        sleeps
        bedrooms
        bathrooms
        type
        latitude
        longitude
        group
        streetAddress1
        # calendar {
        #   start
        #   end
        #   availability
        #   minStay
        #   minStayThresholdAlert
        # }
        virtualTours {
          property {
            name
          }
          url
          additionalMessaging
          thumbnailImage {
            alt
            destination
            caption
            file {
              versions {
                key
                fullUrl
                height
                width
              }
            }
          }
        }
        propertyListings {
          propertyUrl
          platform {
            name
            key
          }
        }
        amenities {
          name
          key
          description
        }
        ... on BHIP_Property {
          floorPlan {
            id
            fileInstance {
              alt
              caption
              destination
              file {
                mimetype
                fullUrl
                width
                height
                versions {
                  fullUrl
                  key
                  width
                  height
                  mimetype
                }
              }
            }
          }
        }
        ... on BHIP_PropertyCombo {
          childProperties {
            name
            key
            longitude
            latitude
            ... on BHIP_Property {
              status

              id
              label
              key
              name
              description
              sleeps
              bedrooms
              bathrooms
              type
              virtualTours {
                property {
                  name
                }
                url
                additionalMessaging
                thumbnailImage {
                  alt
                  destination
                  caption
                  file {
                    versions {
                      key
                      fullUrl
                      height
                      width
                    }
                  }
                }
              }
              highlights {
                id
                files {
                  alt
                  mimetype
                  fullUrl
                  width
                  height
                  versions {
                    key
                    fullUrl
                    width
                    height
                    mimetype
                  }
                }
              }
              floorPlan {
                id
                fileInstance {
                  alt
                  caption
                  destination
                  file {
                    mimetype
                    fullUrl
                    width
                    height
                    versions {
                      fullUrl
                      key
                      width
                      height
                      mimetype
                    }
                  }
                }
              }
              guestBook {
                id
                title
                files {
                  title
                }
              }
            }
            id
            sitePage {
              excerpt
              slug

              status
              featuredImageCollection {
                files {
                  tiny: version(where: { key: "tiny" }) {
                    fullUrl
                  }
                  medium: version(where: { key: "medium" }) {
                    fullUrl
                  }
                }
              }
              cmsContent {
                html
                blockData {
                  key
                  data
                }
              }
              featuredFileCollection {
                id
                files {
                  mimetype
                  fullUrl
                  width
                  height
                  versions {
                    fullUrl
                    key
                    width
                    height
                    mimetype
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`;

export default SingleProperty;
