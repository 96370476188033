import React, { useEffect, useState, useContext } from 'react';
import { Helmet } from 'react-helmet';
import { graphql, Link, navigate } from 'gatsby';
import { Query, useQuery, useMutation } from 'react-apollo';

import gql from 'graphql-tag';
import Cookies from 'js-cookie';
import moment from 'moment';
import * as Yup from 'yup';
// const { Formik, Field } = Loadable(() => import('formik')); // code-splitted, on demand loaded component
import { XCircle, CheckCircle } from 'react-feather';
import { Formik, Field } from 'formik';
import Modal from '../../components/Modal';
import WithLocation from '../../components/WithLocation';
import SEO from '../../components/seo';
import EventTracker from '../../components/EventTracker';
import Layout from '../../layouts/DefaultLayout';
import CMSContent from '../../components/CMSContent';
import PropertyDetailsList from '../../components/PropertyDetailsList';
import Loading, { Dots } from '../../components/Loading';
import { client } from '../../util/apollo/client';
import PropertyQuoteProvider, {
  PropertyQuoteConsumer,
  PropertyQuoteContext,
} from '../../contexts/PropertyQuote';
import PseudoSelect from '../../components/PseudoSelect';
import GuestCounterForm from '../../components/RequestInfoForm/components/GuestCounterForm';
import LazyImage from '../../components/LazyImage';
import styles from './styles.module.scss';
import { getSiteMeta } from '../../util/providers';
import {
  PayPalScriptProvider,
  PayPalButtons,
  usePayPalScriptReducer,
} from '@paypal/react-paypal-js';

const validationSchema = Yup.object().shape({
  emailAddress: Yup.string().email().required('Please provide a valid email address.'),
  // numberOfAdults: Yup.number()
  //   .integer()
  //   .min(1, 'Please tell us about your group.')
  //   .required('Please tell us about your group.'),
});

const Testimonials = () => {
  const [width, setWidth] = useState(null);
  const [scriptLoaded, setScriptLoaded] = useState(false);


  useEffect(() => {
    const scriptTag = document.createElement('script');
    scriptTag.src = 'https://static.elfsight.com/platform/platform.js';
    scriptTag.addEventListener('load', () => setScriptLoaded(true));
    document.body.appendChild(scriptTag);
    setWidth(window.innerWidth);
    window.addEventListener("resize", () => {
      setWidth(window.innerWidth);
    });
    return () => {
      window.removeEventListener("resize", () => { });
    };
  }, []);

  return <>

    <div style={{
      display: scriptLoaded && width <= 768 ? 'none' : 'block'
    }} className="elfsight-app-7343a4ac-a479-4bca-9426-c08cb0b1a72f" data-elfsight-app-lazy></div>
    <div style={{
      display: scriptLoaded && width > 768 ? 'none' : 'block'
    }}
      className="elfsight-app-f4564248-fbb7-4d47-a41b-a46c4b05f657" data-elfsight-app-lazy></div>
  </>
  // return width > 768 ? <>
  //   <script src={`https://static.elfsight.com/platform/platform.js?${width}`} data-use-service-core defer></script>


  // </> : <>
  //   <script src={`https://static.elfsight.com/platform/platform.js?${width}`} data-use-service-core defer></script>

  // </>
}

const QUOTE_DATA = gql`
  query PropertyQuote(
    $propertyId: Int
    $checkIn: Date
    $checkOut: Date
    $numberOfPets: Int
    $promoCode: String
  ) {
    propertyQuote(
      where: {
        propertyId: $propertyId
        checkIn: $checkIn
        checkOut: $checkOut
        numberOfPets: $numberOfPets
        promoCode: $promoCode
      }
    ) {
      baseRate
      rate
      cleaningFee
      petFee
      administrativeFee
     
      damageDeposit
      avgPerNight
      totalNights
      fees
      tax {
        lodgingTax
        countyTax
        stateTax
      }
      paymentRequests {
        description
        date
        amount
      }
      prepaidDiscount {
        percent
        baseRateDiscount
        baseRateDiscountApplied
        rate
        tax {
          lodgingTax
          countyTax
          stateTax
        }
      }
      promoDiscount {
        percent
        baseRateDiscount
        baseRateDiscountApplied
        rate
        tax {
          lodgingTax
          countyTax
          stateTax
        }
      }      
      property {
        id
        name
        sleeps
        bedrooms
        bathrooms
        stateTaxRate
        countyTaxRate
        petFee
      }
    }
  }
`;

const CREATE_PAYPAL_ORDER = gql`
  mutation CreatePaypalOrder(
    $checkIn: String!
    $checkOut: String!
    $rateToValidate: Float!
    $prepaidDiscountRequested: Boolean
    $promoCode: String
    $propertyId: Int!
    $address: String
    $city: String
    $state: String
    $postalCode: String
    $country: String
    $firstName: String
    $lastName: String
    $emailAddress: String!
    $phoneNumber: String
    $additionalDetails: String
    $numberOfAdults: Int
    $numberOfChildren: Int
    $numberOfPets: Int
    $numberOfVehicles: Int
    $numberOfVisitors: Int
  ) {
    createPaypalOrder(
      data: {
        checkIn: $checkIn
        checkOut: $checkOut
        rateToValidate: $rateToValidate
        promoCode: $promoCode
        propertyId: $propertyId
        address: $address
        city: $city
        state: $state
        postalCode: $postalCode
        country: $country
        firstName: $firstName
        lastName: $lastName
        emailAddress: $emailAddress
        phoneNumber: $phoneNumber
        additionalDetails: $additionalDetails
        numberOfAdults: $numberOfAdults
        numberOfChildren: $numberOfChildren
        numberOfPets: $numberOfPets
        numberOfVehicles: $numberOfVehicles
        numberOfVisitors: $numberOfVisitors
        prepaidDiscountRequested: $prepaidDiscountRequested
      }
    ) {
      data
    }
  }
`;


const getFinalPrice = ({ propertyQuote, prepaidDiscountRequested }) => {
  if (prepaidDiscountRequested && propertyQuote?.prepaidDiscount?.rate) {
    return propertyQuote?.prepaidDiscount.rate;
  }
  return propertyQuote?.paymentRequests[0].amount;
}

// const getFinalPriceForValidation = ({ promo, propertyQuote, prepaidDiscountRequested }) => {
//   if (prepaidDiscountRequested && propertyQuote?.prepaidDiscount?.rate) {
//     return propertyQuote?.prepaidDiscount.rate;
//   }
//   return propertyQuote?.paymentRequests[0].amount; 
// }

const ButtonWrapper = ({ showSpinner, promo, values, context, errors, search }) => {
  const [{ isPending }] = usePayPalScriptReducer();
  // const [loading, setLoading] = useState(false)
  console.log('search2', search)
  console.log('context', context)
  // console.log('errors', errors);
  let invoiceId = null;
  // console.log('client', client);
  const [paypalMessagingModalIsOpen, setPaypalMessagingModalIsOpen] = useState(
    false
  );
  const [
    paypalErrorMessagingModalIsOpen,
    setPaypalErrorMessagingModalIsOpen,
  ] = useState(false);

  const propertyQuoteContext = useContext(PropertyQuoteContext);
  const createPaypalOrder = async ({ variables }) =>
    client.mutate({
      mutation: CREATE_PAYPAL_ORDER,
      variables: variables,
    });

  function createOrder({ values, rateToValidate }) {
    console.log('context2', context)
    const prepaidDiscount = context.state.prepaidDiscount == 'YES';
    return (
      createPaypalOrder({
        variables: {
          ...values,
          //country: 'US',
          rateToValidate,
          prepaidDiscountRequested: prepaidDiscount,
          promoCode: promo
        },
      })
        // .then(response => response.json())
        .then(response => {
          // Your code here after create the order
          console.log('response', response);
          console.log(
            ' response.data.createPaypalOrder.data.id',
            response.data.createPaypalOrder.data.id
          );
          return response.data.createPaypalOrder.data.id;
        }).catch(err => {
          console.log('err', err)
          // if (err?.graphQLErrors) {
          //   console.log('err2', err?.graphQLErrors)
          //   // context.setErrors(err?.graphQLErrors);
          // }
          alert(err.message.replace(/graphql error:/gi, ''))
        })
    );
  }
  function onApprove(data, trackEvent) {
    // replace this url with your server
    console.log('onApprove:data', data);
    return fetch(`${process.env.BHIP_API_URL}/graphql`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      // use the "body" param to optionally pass additional order information
      // like product ids and quantities
      body: JSON.stringify({
        query: `{
          getPaypalOrderDetails(where: {orderId: "${data.orderID}"}) {
            data
          }
        }`,
      }),
      // body: JSON.stringify({
      //     cart: [
      //         {
      //             sku: "1blwyeo8",
      //             quantity: 2,data.orderId
      //         },
      //     ],
      // }),
    })
      .then(response => response.json())
      .then(orderData => {
        // Your code here after capture the order
        console.log('onApprove:orderdata', orderData);
        console.log('onApprove:propertyQuoteContext', propertyQuoteContext);
        console.log('onApprove:orderData.data.getPaypalOrderDetails.data.status', orderData.data.getPaypalOrderDetails.data.status);
        if (orderData.data.getPaypalOrderDetails.data.status == 'COMPLETED') {
          console.log('onApprove:window', typeof window);
          //setPaypalMessagingModalIsOpen(true);
          if (typeof window != 'undefined') {

            // window.dataLayer = window.dataLayer || [];
            // function gtag() { dataLayer.push(arguments); }

            // console.log('onApprove:gtag');
            // gtag('js', new Date());
            // gtag('config', 'G-9P4L5V43YE');
            try {
              console.log('onApprove:try');
              const propertyQuote = propertyQuoteContext.state.propertyQuote;

              trackEvent('purchase', {
                transactionID: `PP-${data.orderID}`,
                transactionValue: propertyQuote.promoDiscount ? propertyQuote.promoDiscount.rate : propertyQuote.rate,  // Updated value: $2000 (after discount) + $482 (taxes) + $698 (fees)
                currency: "USD",
                tax: propertyQuote.promoDiscount ? propertyQuote.promoDiscount.tax.lodgingTax : propertyQuote.tax.lodgingTax,  // Added taxes
                coupon: propertyQuote.promoDiscount ? "halfoff_2024" : propertyQuote.promoDiscount,
                fees: propertyQuote.promoDiscount ? propertyQuote.promoDiscount.fees : propertyQuote.fees,  // Custom parameter for fees
                items: [{
                  item_id: propertyQuote.property.id,
                  item_name: propertyQuote.property.name,
                  item_category: "Vacation Rental",
                  price: propertyQuote.rate,  // Original price before discount
                  discount: propertyQuote.promoDiscount ? propertyQuote.promoDiscount.baseRateDiscountApplied : 0,
                  quantity: 1
                }]
              });
              console.log('onApprove:gtag:purchase');
            } catch (err) {
              console.log('gtagError', err)
            }
            navigate('/rentals', {
              state: {
                paypalSuccess: {
                  propertyQuote: propertyQuoteContext.state.propertyQuote,
                  checkIn: search.checkin,
                  checkOut: search.checkout,
                }
              }
            });
          }




        } else {
          setPaypalErrorMessagingModalIsOpen(true);
        }
      })
      .catch(() => {
        setPaypalErrorMessagingModalIsOpen(true);
      });
  }

  return (
    <>

      {((showSpinner && isPending)) && <div className="spinner" />}

      <Modal
        // expanded={true}
        disableFocusTrap={true}
        isOpen={paypalErrorMessagingModalIsOpen}
        customHeader={() => {
          return (
            <>
              <h5 className="modal-title">
                <XCircle color="red" className="mr-1" />
                We're sorry ...
              </h5>
            </>
          );
        }}
        onClose={() => setPaypalErrorMessagingModalIsOpen(false)}
      >
        <>
          <p className="mb-2">
            Something went wrong. Rest assured, no charges have been made to
            your account. Please email us to help with your reservation.
          </p>
          <a
            href="mailto:BeachHousesinParadise@gmail.com"
            className="btn btn-secondary mt-2 text-primary font-weight-bold w-100"
          >
            <span className="font-weight-bold">Email Support</span>
          </a>
        </>
      </Modal>
      <Modal
        // expanded={true}
        disableFocusTrap={true}
        isOpen={paypalMessagingModalIsOpen}
        title={'Property Reservation'}
        customHeader={() => {
          return (
            <>
              <h5 className="modal-title">
                <CheckCircle color="green" className="mr-1" />
                Thank you for your payment!
              </h5>
            </>
          );
        }}
        onClose={() => setPaypalMessagingModalIsOpen(false)}
      >
        <>
          <p className="mb-2">
            Your dates are now reserved. We will email you shortly to confirm
            your details and finalize your reservation.
          </p>
          <div className="mb-1plus">
            {propertyQuoteContext.state.propertyQuote?.property?.name}
          </div>
          <div className="mb-1plus">
            <span>
              {moment(search.checkin)
                .format('dddd')
                .substring(0, 3) +
                ', ' +
                moment(search.checkin).format('MMM DD, YYYY')}{' '}
            </span>{' '}
            -{' '}
            <span>
              {moment(search.checkout)
                .format('dddd')
                .substring(0, 3) +
                ', ' +
                moment(search.checkout).format('MMM DD, YYYY')}{' '}
            </span>
          </div>
          <button
            className="btn btn-secondary mt-2 text-primary font-weight-bold w-100"
            onClick={() => {
              setPaypalMessagingModalIsOpen(false);
            }}
          >
            OK
          </button>

          {/* 
Thank you!

 
Bella Vista
Mon, Nov 13, 2023 - Sat, Nov 18, 2023
 
Button text: [OK]

Error modal:

We're sorry ...
Something went wrong. Rest assured, no charges have been made to your account. Please email us for help with your reservation.
 
Button Text: [Email Support]

Site messaging (if we recalculate for availability):
The dates you selected are no longer available. */}
        </>
      </Modal>
      <EventTracker>
        {({ trackEvent }) => {
          return (
            <PayPalButtons
              style={{ layout: 'vertical' }}
              disabled={false}
              forceReRender={[{ layout: 'vertical' }]}
              fundingSource={'paypal'}

              // onClick={(data, actions) => {
              //   console.log('values', values);
              //   console.log('data', data);
              //   console.log('actions', actions);
              //   return actions.reject(); // TODO: Validate
              //   // return actions.resolve();
              //   // return validateAndInvoice({
              //   //   values,
              //   //   rateToValidate: propertyQuoteContext.state.propertyQuote?.rate,
              //   // }).then(() => {
              //   //   console.log('invoiceId', invoiceId);
              //   //   if (invoiceId) {
              //   //     console.log('RESOLVED');
              //   //     return actions.resolve();
              //   //   }

              //   //   return actions.reject();
              //   // });
              // }}
              createOrder={args => {

                console.log('args', args);
                const rateToValidate = getFinalPrice({ propertyQuote: propertyQuoteContext.state.propertyQuote, prepaidDiscountRequested: propertyQuoteContext.state.prepaidDiscount == 'YES' });
                console.log('rateToValidate', rateToValidate)
                return createOrder({
                  values,
                  rateToValidate,
                });
              }}
              onApprove={(data) => {
                return onApprove(data, trackEvent)
              }}

            />
          )
        }}</EventTracker>
      {/* <PayPalButtons
        style={{ layout: 'vertical' }}
        disabled={false}
        forceReRender={[{ layout: 'vertical' }]}
        fundingSource={'card'}
        createOrder={async args => {
          //  setLoading(true);
          console.log('args2', args);
          const rateToValidate = getFinalPrice({ propertyQuote: propertyQuoteContext.state.propertyQuote, prepaidDiscountRequested: propertyQuoteContext.state.prepaidDiscount == 'YES' });
          console.log('rateToValidate2', rateToValidate)
          // const result = await createOrder({
          //   values,
          //   rateToValidate,
          // });

          return createOrder({
            values,
            rateToValidate,
          });
        }}
        onApprove={onApprove}
      /> */}
    </>
  );
};

const QuotePanel = ({
  search,
  location,
  numberOfPets,
  guestNumbersLabel,
  propertyId,
  property,
  promo,
  prepaidDiscountRequested,
  priceDetailModalIsOpen,
  onPriceDetailClick
}) => {

  const [errorModalIsOpen, setErrorModalIsOpen] = useState(false);

  //console.log('numberOfPets2', numberOfPets);
  const propertyQuoteContext = useContext(PropertyQuoteContext);

  const { data: quoteData, error, loading, refetch } = useQuery(QUOTE_DATA, {
    // skip: numberOfPets == null,
    variables: {
      propertyId: propertyId,
      numberOfPets: numberOfPets,
      checkIn: search.checkin,
      checkOut: search.checkout,
      promoCode: promo
    },
  });

  useEffect(() => {
    if (error?.graphQLErrors) {
      //  console.log('error', error.message);
      // propertyQuoteContext.setErrors(error?.graphQLErrors);
      setErrorModalIsOpen(true);
    }
  }, [error?.graphQLErrors]);

  //console.log('location', location);
  if (loading) {
    return (
      <div className=" mt-3">
        <Loading />
      </div>
    );
  }
  if (error?.graphQLErrors) {
    return (
      <Modal
        // expanded={true}
        disableFocusTrap={true}
        isOpen={errorModalIsOpen}
        title={'Expired Quote'}
        customHeader={() => {
          return (
            <>
              <h5 className="modal-title">
                <XCircle color="red" className="mr-1" />
                Expired Quote
              </h5>
            </>
          );
        }}
        onClose={() => setErrorModalIsOpen(false)}
      >
        <>
          <p className="mb-2">Quote Expired</p>

          <button
            className="btn btn-secondary mt-2 text-primary font-weight-bold w-100"
            onClick={() => {
              //              setErrorModalIsOpen(false);
              window.location.href =
                window.location.pathname.replace('/reserve', '') +
                '?reserve=true';
            }}
          >
            Go Back
          </button>
        </>
      </Modal>
    );
  }
  // console.log('error', error);
  // console.log('error?graphQLErrors', error?.graphQLErrors);
  // if (Array.isArray(errors.graphQLErrors)) {
  //   if (errors.graphQLErrors.length > 0) {
  //     return errors.graphQLErrors[0].message;
  //   }
  // }

  const { propertyQuote } = quoteData;
  propertyQuoteContext.setPropertyQuote(propertyQuote);
  console.log('property', property);
  let discountNode = propertyQuote.promoDiscount
  if (prepaidDiscountRequested == 'YES' && propertyQuote.prepaidDiscount) {
    discountNode = propertyQuote.prepaidDiscount
  }
  //console.log('propertyQuote2', propertyQuote)
  // const { property } = propertyQuote;
  return (
    <>
      <div>
        <img
          alt={`${property.name} vacation rental property`}
          src={
            property.sitePage.featuredImageCollection.files[0].medium.fullUrl
          }
        />
      </div>

      <div className="form-group">
        <div className="border-bottom mt-2 mb-2 pb-2">
          <div className="d-flex  flex-wrap  align-items-middle justify-content-between">
            <h2 className="m-0">{property.name}</h2>
            <span>
              <img src="/icon-clean.svg" className="mr-1" />
              Spotlessly Clean
            </span>
          </div>
          <div className={`mt-2 ${styles.propertyDetailParent}`}>
            <PropertyDetailsList property={property} wrap={true} />
          </div>
        </div>
      </div>

      <div className="form-group">
        <div className="border-bottom mt-2 mb-2 pb-2">
          <div className="d-flex  flex-wrap justify-content-between mb-1plus">
            <b>Check-in</b>
            <span>
              {moment(search.checkin)
                .format('dddd')
                .substring(0, 3) +
                ', ' +
                moment(search.checkin).format('MMM DD, YYYY')}{' '}
              (at 5pm)
            </span>
          </div>
          <div className="d-flex  flex-wrap justify-content-between">
            <b>Check-out</b>
            <span>
              {moment(search.checkout)
                .format('dddd')
                .substring(0, 3) +
                ', ' +
                moment(search.checkout).format('MMM DD, YYYY')}{' '}
              (at 11am)
            </span>
          </div>
        </div>
      </div>

      {/* <div className="form-group">
        <div className="border-bottom mt-2 mb-3 pb-2">
          <div className="d-flex justify-content-between mb-1plus">
            <b>Guest info</b>
            <span>{guestNumbersLabel || 'Required'}</span>
          </div>
        </div>
      </div> */}
      <div className="form-group">

        <div className="border-bottom mt-2 mb-3 pb-2">
          <div className="d-flex justify-content-between mb-1plus">
            <b>{propertyQuote.totalNights} x nights</b>
            <span style={{ 'textDecoration': promo == 'halfoff_2024' && propertyQuote.promoDiscount ? 'line-through' : '' }}>{moneyFormatter.format(propertyQuote.baseRate)}</span>
          </div>

          {promo == 'halfoff_2024' && propertyQuote.promoDiscount &&
            <div className="d-flex justify-content-between mb-1plus text-success">
              <b>Promotional Rate</b>
              <b>{moneyFormatter.format(propertyQuote.promoDiscount.baseRateDiscountApplied)}</b>
            </div>}
          {prepaidDiscountRequested == 'YES' && propertyQuote.prepaidDiscount && <div className="d-flex justify-content-between mb-1plus">
            <span>Discount (10%)</span>
            <span >-{moneyFormatter.format(
              (propertyQuote.promoDiscount ? propertyQuote.promoDiscount.baseRateDiscountApplied : propertyQuote.baseRate) / propertyQuote.prepaidDiscount.percent
            )}</span>
          </div>}
          <div className="d-flex justify-content-between mb-1plus">
            <span>Fees</span>
            <span>{moneyFormatter.format(propertyQuote.fees)}</span>
          </div>
          <div className="d-flex justify-content-between mb-1plus">
            <span>Taxes</span>
            {discountNode ?
              <span>{moneyFormatter.format(discountNode.tax?.lodgingTax)}</span> :
              <span>{moneyFormatter.format(propertyQuote.tax.lodgingTax)}</span>}
          </div>
          {/* {numberOfPets > 0 && (
            <div className="border-bottom d-flex justify-content-between pb-2 mb-2">
              <b>Pet Fee</b>
              <span>{moneyFormatter.format(propertyQuote.petFee)}</span>
            </div>
          )}
          <div className="border-bottom d-flex justify-content-between pb-2 mb-2">
            <b>Total</b>
            <span>{moneyFormatter.format(propertyQuote.rate)}</span>
          </div> */}
          <div className="text-right">
            <button
              className="btn btn-link p-0 outline-none"
              style={{ outline: 'none' }}
              onClick={() => {
                onPriceDetailClick(true)
              }}
              href="#"
            >
              Price Details
            </button>
          </div>
          <Modal
            // expanded={true}
            disableFocusTrap={true}
            isOpen={priceDetailModalIsOpen}
            title={'Price Details'}
            onClose={() => onPriceDetailClick(false)}
          >
            <>
              <div className="d-flex justify-content-between mb-1plus">
                <span>{propertyQuote.totalNights} x nights</span>
                <span style={{ 'textDecoration': propertyQuote.promoDiscount ? 'line-through' : '' }}>{moneyFormatter.format(propertyQuote.baseRate)}</span>
              </div>

              {propertyQuote.promoDiscount &&
                <div className="d-flex justify-content-between mb-1plus text-success">
                  <b>Promotional Rate  {propertyQuote.totalNights > 7 && <>(applied to first 7 nights)</>}</b>
                  <b>{moneyFormatter.format(propertyQuote.promoDiscount.baseRateDiscountApplied)}</b>
                </div>}
              {prepaidDiscountRequested == 'YES' && propertyQuote.prepaidDiscount && <div className="d-flex justify-content-between mb-1plus">
                <span>Discount (10%)</span>
                <span >-{moneyFormatter.format(
                  (propertyQuote.promoDiscount ? propertyQuote.promoDiscount.baseRateDiscountApplied : propertyQuote.baseRate) / propertyQuote.prepaidDiscount.percent
                )}</span>




              </div>}
              <div className="d-flex justify-content-between mb-1plus">
                <span>Cleaning & Restocking Fee</span>
                <span>{moneyFormatter.format(propertyQuote.cleaningFee)}</span>
              </div>
              <div className="d-flex justify-content-between mb-1plus">
                <span>Administrative Fee</span>
                <span>
                  {moneyFormatter.format(propertyQuote.administrativeFee)}
                </span>
              </div>
              {numberOfPets > 0 && (
                <div className="d-flex justify-content-between mb-1plus">
                  <span>
                    Pet Fee {numberOfPets > 1 && <>({numberOfPets} pets)</>}
                  </span>
                  <span>{moneyFormatter.format(propertyQuote.petFee)}</span>
                </div>
              )}
              <div className="d-flex justify-content-between mb-1plus">
                <span>FL & County Sales Tax ({propertyQuote.property.stateTaxRate}%)</span>
                {discountNode ? <span>{moneyFormatter.format(discountNode.tax?.stateTax)}</span> : <span>{moneyFormatter.format(propertyQuote.tax.stateTax)}</span>}
              </div>
              <div className="d-flex justify-content-between mb-1plus">
                <span>Hotel / Bed / Tourism Tax ({propertyQuote.property.countyTaxRate}%)</span>
                {discountNode ? <span>{moneyFormatter.format(discountNode.tax?.countyTax)}</span> : <span>{moneyFormatter.format(propertyQuote.tax.countyTax)}</span>}

              </div>

              <div className="d-flex justify-content-between mb-1plus border-top mt-2 pt-2">
                <b>Total</b>
                {discountNode ? <b>{moneyFormatter.format(discountNode.rate)}</b> : <b>{moneyFormatter.format(propertyQuote.rate)}</b>}
              </div>
              <div className="mt-2 small">Rates are automatically re-calibrated nightly, based on supply and demand.</div>
              {promo == 'halfoff_2024' && propertyQuote.promoDiscount && propertyQuote.totalNights > 7 && <div className="small">Promotional rate discounts are applied to the first seven nights.</div>}
            </>
          </Modal>
        </div>
      </div>
    </>
  );
};

const moneyFormatter = new Intl.NumberFormat('en-US', {
  style: 'currency',
  currency: 'USD',

  // These options are needed to round to whole numbers if that's what you want.
  //minimumFractionDigits: 0, // (this suffices for whole numbers, but will print 2500.10 as $2,500.1)
  //maximumFractionDigits: 0, // (causes 2500.99 to be printed as $2,501)
});

const ReserveDatesPage = ({
  data,
  pageContext,
  search,
  location,
  ...props
}) => {
  // const { siteTagGroup } = data.bhip || {};
  // const { siteTags } = siteTagGroup || {};
  // const [priceDetailModalIsOpen, setPriceDetailModalIsOpen] = useState(false);
  const [guestNumbersLabel, setGuestNumbersLabel] = useState(null);
  const [formIsValid, setFormIsValid] = useState(false);
  const [numberOfPets, setNumberOfPets] = useState(null);
  const [numberOfAdults, setNumberOfAdults] = useState(0);
  // console.log('numberOfPets1', numberOfPets);
  const [priceDetailModalIsOpen, setPriceDetailModalIsOpen] = useState(false);
  const params = new URLSearchParams(location?.search);
  const promo = params.get('promo') ? params.get('promo') : Cookies.get('promo');
  const sandbox = params.get('sandbox') ? params.get('sandbox') : false;
  // console.log('props', props);

  const siteTags = data?.bhip?.siteTagGroup?.siteTags || [];
  const property = data?.bhip?.property || {};
  const { sitePage } = data?.bhip;
  const siteMeta = getSiteMeta();

  return (
    <Layout searchbarLabel="Reserve Your Dates">
      <SEO
        title={sitePage.seoTitle}
        description={sitePage.seoDescription}
        headTags={sitePage.headTags}
      />



      <div className="container mt-3">
        <p id="top-paragraph">
          Reserve your dates at {property.name}. Upon completing your payment,
          we will contact you to confirm your details and finalize your
          reservation.
        </p>
        <PropertyQuoteProvider>
          <div className="row  flex-column-reverse flex-md-row">
            <div className="col col-12 col-md-7 col-xl-8">
              <div className="border-bottom mt-2 mb-3 pb-2">
                <h2 className="m-0 d-flex">
                  <div className={styles.chevron}>1</div> Your information
                </h2>
              </div>
              <Formik
                validationSchema={validationSchema}
                initialValues={{
                  propertyId: property.id,
                  checkIn: search.checkin,
                  checkOut: search.checkout,
                  // firstName: '',
                  // lastName: '',
                  emailAddress: '',
                  // phoneNumber: '',
                  // address: '',
                  // city: '',
                  // state: '',
                  // postalCode: '',
                  // country: '',
                  // additionalDetails: '',
                  // numberOfAdults: 0,
                  // numberOfChildren: 0,
                  // numberOfPets: 0,
                  // numberOfVehicles: 0,
                  // numberOfVisitors: 0,
                }}
                onSubmit={async (
                  values,
                  { setSubmitting, setStatus, setErrors, resetForm }
                ) => {
                  console.log('values1', values);
                }}
              >
                {formikProps => {
                  const {
                    values,
                    errors,
                    handleChange,
                    handleSubmit,
                    isSubmitting,
                    setFieldValue,
                    touched,
                    status,
                    resetForm,
                    submitCount,
                    setStatus,
                  } = formikProps;

                  // console.log('values', values);
                  // console.log('errors', errors);
                  // console.log('touched', touched);

                  return (
                    <form id="reserveForm" onSubmit={handleSubmit}>

                      <div className="form-group">
                        <label htmlFor="email">
                          <b>Email</b>
                          <span className="text-danger ml-1">*</span>
                        </label>
                        <input
                          type="email"
                          onChange={handleChange}
                          className="form-control rounded"
                          name="emailAddress"
                          id="email"
                          placeholder=""
                          required
                        />
                        We'll send a confirmation email to this address, please
                        ensure it's accurate.
                      </div>

                      <div className="form-group">
                        <div className="border-bottom mt-4 mb-1 pb-2">
                          <h2 className="m-0 d-flex">
                            <div className={styles.chevron}>2</div> Flexible
                            cancellation policy
                          </h2>
                        </div>
                        <p>
                          <i>Secure Your Trip with Confidence!</i>
                          <br />
                          <br />
                          <b>100% Full Refund Guarantee:</b> Cancel up to 30
                          days before check-in for a full refund.
                          <br />
                          <br />
                          <b>50% Partial Refund Option:</b> Cancel 14-30 days
                          before check-in for a generous 50% refund.
                          {/* <br />
                          <br />
                          <b>No Refund:</b> Cancellations within 14 days of the
                          check-in date will not be eligible for a refund.
                          <br />
                          <br />
                          Cancellation deadlines are in EST (GMT -5) time zone. */}
                        </p>
                      </div>
                      <PropertyQuoteConsumer>
                        {context => {
                          // console.log('context', context);
                          return context.state.propertyQuote ? (
                            <>
                              <div className="form-group">
                                <div className="border-bottom mt-2 mb-3 pb-2">
                                  <h2 className="m-0 d-flex">
                                    <div className={styles.chevron}>3</div> Payment
                                    details
                                  </h2>
                                </div>
                                {/* 
                                <div className="custom-control custom-radio custom-control-inline">
                                  <input
                                    type="radio"
                                    id="payPal1"
                                    name="payPal"
                                    className="custom-control-input"
                                    checked
                                    value="YES"
                                  // onChange={e => {
                                  //   setMobilityModalOpen(true);
                                  //   handleChange(e);
                                  // }}
                                  />
                                  <label
                                    className="custom-control-label"
                                    htmlFor="payPal1"
                                  >
                                    <img
                                      src="/paypal-logo.svg"
                                      width="94"
                                      height="25"
                                    />
                                  </label>
                                </div> */}

                                {/* <div className="mt-2">
                                  <b>Paying with PayPal</b>
                                  <br />
                                  <br />
                                  When you click <b>Continue to PayPal</b> below, you'll
                                  be redirected to the PayPal site to complete your
                                  payment. Even if you don't have a PayPal account, you
                                  can pay as a guest, using a credit or debit card.
                                </div> */}


                                <div className="form-group">


                                  {
                                    !context.state.propertyQuote.prepaidDiscount && context.state.propertyQuote?.paymentRequests.length == 1 && <>
                                      <div className="mt-3">

                                        <div>

                                          <div className="border p-2 mt-3">
                                            <div><b>Payment Schedule</b></div>
                                            {context.state.propertyQuote?.promoDiscount ? <><table className="mt-2">
                                              <tr>
                                                <td>Original Owner Rate</td>
                                                <td className="font-weight-bold pl-3 text-right">{moneyFormatter.format(
                                                  context.state.propertyQuote?.baseRate
                                                )}</td>
                                              </tr>


                                              <tr>
                                                <td className="font-weight-bold  text-success"><b>Promotional Rate ({context.state.propertyQuote?.promoDiscount.percent}% Off)</b></td>
                                                <td className="font-weight-bold text-success pl-3 text-right"><b>{moneyFormatter.format(
                                                  context.state.propertyQuote?.promoDiscount?.baseRateDiscountApplied
                                                )}</b></td>
                                              </tr>

                                              <tr>
                                                <td>Taxes & Fees (<button type="button" onClick={setPriceDetailModalIsOpen} className="btn btn-link text-primary p-0">details</button>)</td>
                                                <td className="font-weight-bold pl-3 text-right">{moneyFormatter.format(
                                                  context.state.propertyQuote?.promoDiscount?.tax?.lodgingTax + context.state.propertyQuote?.fees
                                                )}</td>
                                              </tr>
                                              <tr>
                                                <td>Total (Due Now)</td>
                                                <td className="font-weight-bold pl-3 text-right">{moneyFormatter.format(
                                                  context.state.propertyQuote?.promoDiscount?.baseRateDiscountApplied + context.state.propertyQuote?.promoDiscount?.tax?.lodgingTax + context.state.propertyQuote?.fees
                                                )}</td>
                                              </tr>


                                            </table>

                                              {context.state?.propertyQuote.totalNights > 7 ? <>
                                                <div className="mt-3">Save <b>{context.state.propertyQuote?.promoDiscount.percent}%</b> off of the owner's rate (applies to first seven nights only)</div></> :
                                                <div className="mt-3">Includes <b>{context.state.propertyQuote?.promoDiscount.percent}%</b> discount on owner's rate of <b>{moneyFormatter.format(
                                                  context.state.propertyQuote?.baseRate
                                                )}</b></div>
                                              }


                                            </> : <><table className="mt-2">

                                              <tr>
                                                <td>Original Owner Rate</td>
                                                <td className="font-weight-bold pl-3 text-right">{moneyFormatter.format(
                                                  context.state.propertyQuote?.baseRate
                                                )}</td>
                                              </tr>


                                              <tr>
                                                <td>Taxes & Fees (<button type="button" onClick={setPriceDetailModalIsOpen} className="btn btn-link text-primary p-0">details</button>)</td>
                                                <td className="font-weight-bold pl-3 text-right">{moneyFormatter.format(
                                                  context.state.propertyQuote?.tax?.lodgingTax + context.state.propertyQuote?.fees
                                                )}</td>
                                              </tr>
                                              <tr>
                                                <td>Total (Due Now)</td>
                                                <td className="font-weight-bold pl-3 text-right">{moneyFormatter.format(
                                                  context.state.propertyQuote?.baseRate + context.state.propertyQuote?.tax?.lodgingTax + context.state.propertyQuote?.fees
                                                )}</td>
                                              </tr>

                                            </table></>}
                                          </div>
                                        </div>
                                      </div>
                                    </>}

                                  {
                                    context.state.propertyQuote.prepaidDiscount && context.state.propertyQuote?.paymentRequests.length > 1 && <>
                                      <div className="mt-3">
                                        <div><b>Payment Options</b></div>
                                        <div>

                                          <div className="mt-1plus custom-control custom-radio custom-control-inline">
                                            <input
                                              type="radio"
                                              id="prepaidDiscountOption1"
                                              name="prepaidDiscountOption"
                                              className="custom-control-input"
                                              checked={context.state.prepaidDiscount == 'YES'}
                                              value="YES"
                                              onChange={e => {
                                                console.log('change', e)
                                                console.log('context', context)
                                                context.setPrepaidDiscount('YES');
                                                //  handleChange(e);
                                              }}
                                            />
                                            <label
                                              className="custom-control-label"
                                              htmlFor="prepaidDiscountOption1"
                                            >
                                              Pay 100% now, save 10% on
                                              {promo == 'halfoff_2024' && context.state.propertyQuote?.promoDiscount ? <> promotional rate</> : <> owner's rate</>}
                                            </label>
                                          </div>
                                        </div>


                                        <div>
                                          <div className="mt-1plus  custom-control custom-radio custom-control-inline">
                                            <input
                                              type="radio"
                                              id="prepaidDiscountOption2"
                                              name="prepaidDiscountOption"
                                              className="custom-control-input"
                                              checked={context.state.prepaidDiscount == 'NO'}
                                              value="NO"
                                              onChange={e => {
                                                console.log('change', e)
                                                context.setPrepaidDiscount('NO');
                                                // handleChange(e);
                                              }}
                                            />
                                            <label
                                              className="custom-control-label"
                                              htmlFor="prepaidDiscountOption2"
                                            >
                                              Pay half now, half 45 days prior to check-in
                                            </label>
                                          </div>
                                        </div>
                                      </div>
                                      {context.state.prepaidDiscount == 'YES' && context.state.propertyQuote?.paymentRequests.length > 1 && <div className="border p-2 mt-3">
                                        <div><b>Payment Schedule</b></div>
                                        <table className="my-2">


                                          {promo == 'halfoff_2024' && context.state.propertyQuote?.promoDiscount ?
                                            <>




                                              <tr>
                                                <td>Original Owner Rate</td>
                                                <td className="font-weight-bold pl-3 text-right">{moneyFormatter.format(
                                                  context.state.propertyQuote?.baseRate
                                                )}</td>
                                              </tr>
                                              <tr>
                                                <td className="font-weight-bold  text-success"><b>Promotional Rate ({context.state.propertyQuote?.promoDiscount.percent}% Off)</b></td>
                                                <td className="font-weight-bold text-success pl-3 text-right"><b>{moneyFormatter.format(
                                                  context.state.propertyQuote?.promoDiscount?.baseRateDiscountApplied
                                                )}</b></td>
                                              </tr>
                                              <tr>
                                                <td>Discount (10%)</td>
                                                <td className="font-weight-bold pl-3 text-right">-{moneyFormatter.format(
                                                  context.state.propertyQuote?.promoDiscount.baseRateDiscountApplied / context.state.propertyQuote?.prepaidDiscount?.percent
                                                )}</td>
                                              </tr>
                                              <tr>
                                                <td>Taxes & Fees (<button type="button" onClick={setPriceDetailModalIsOpen} className="btn btn-link text-primary p-0">details</button>)</td>
                                                <td className="font-weight-bold pl-3 text-right">{moneyFormatter.format(
                                                  context.state.propertyQuote?.prepaidDiscount?.tax?.lodgingTax + context.state.propertyQuote?.fees
                                                )}</td>
                                              </tr>
                                              <tr>
                                                <td>Total (Due Now)</td>
                                                <td className="font-weight-bold pl-3 text-right">{moneyFormatter.format(
                                                  context.state.propertyQuote?.prepaidDiscount?.baseRateDiscountApplied + context.state.propertyQuote?.prepaidDiscount?.tax?.lodgingTax + context.state.propertyQuote?.fees
                                                )}</td>
                                              </tr>


                                            </> :
                                            <>
                                              <tr>
                                                <td>Original Owner Rate</td>
                                                <td className="font-weight-bold pl-3 text-right">{moneyFormatter.format(
                                                  context.state.propertyQuote?.baseRate
                                                )}</td>
                                              </tr>
                                              <tr>
                                                <td>Discount (10%)</td>
                                                <td className="font-weight-bold pl-3 text-right">-{moneyFormatter.format(
                                                  context.state.propertyQuote?.baseRate / context.state.propertyQuote?.prepaidDiscount?.percent
                                                )}</td>
                                              </tr>
                                              <tr>
                                                <td>Taxes & Fees (<button type="button" onClick={setPriceDetailModalIsOpen} className="btn btn-link text-primary p-0">details</button>)</td>
                                                <td className="font-weight-bold pl-3 text-right">{moneyFormatter.format(
                                                  context.state.propertyQuote?.prepaidDiscount?.tax?.lodgingTax + context.state.propertyQuote?.fees
                                                )}</td>
                                              </tr>
                                              <tr>
                                                <td>Total (Due Now)</td>
                                                <td className="font-weight-bold pl-3 text-right">{moneyFormatter.format(
                                                  context.state.propertyQuote?.prepaidDiscount?.baseRateDiscountApplied + context.state.propertyQuote?.prepaidDiscount?.tax?.lodgingTax + context.state.propertyQuote?.fees
                                                )}</td>
                                              </tr>
                                            </>}


                                        </table>

                                        {context.state.propertyQuote?.promoDiscount ? <>
                                          {context.state?.propertyQuote.totalNights > 7 ? <>
                                            <div className="mt-3">Save <b>{context.state.propertyQuote?.promoDiscount.percent}%</b> off of the owner's rate (applies to first seven nights only)</div></> :
                                            <div className="mt-3">Includes <b>{context.state.propertyQuote?.promoDiscount.percent}%</b> discount on owner's rate of <b>{moneyFormatter.format(
                                              context.state.propertyQuote?.baseRate
                                            )}</b></div>
                                          }

                                          Includes <b>10%</b> discount on the promotional rate of <b>{moneyFormatter.format(
                                            context.state.propertyQuote?.promoDiscount.baseRateDiscountApplied
                                          )}</b>
                                        </> : <>
                                          Includes <b>10%</b> discount on owner's nominal rate of <b>{moneyFormatter.format(
                                            context.state.propertyQuote?.baseRate
                                          )}</b>
                                        </>
                                        }
                                      </div>}
                                      {context.state.prepaidDiscount == 'NO' && context.state.propertyQuote?.paymentRequests.length > 1 && <div className="border p-2 mt-3">
                                        <div><b>Payment Schedule</b></div>
                                        <table className="my-2">



                                          {context.state.propertyQuote?.promoDiscount ?
                                            <>



                                              <tr>
                                                <td>Original Owner Rate</td>
                                                <td className="font-weight-bold pl-3 text-right">{moneyFormatter.format(
                                                  context.state.propertyQuote?.baseRate
                                                )}</td>
                                              </tr>
                                              <tr>
                                                <td className="font-weight-bold  text-success"><b>Promotional Rate ({context.state.propertyQuote?.promoDiscount.percent}% Off)</b></td>
                                                <td className="font-weight-bold text-success pl-3 text-right"><b>{moneyFormatter.format(
                                                  context.state.propertyQuote?.promoDiscount?.baseRateDiscountApplied
                                                )}</b></td>
                                              </tr>

                                              <tr>
                                                <td>Taxes & Fees (<button type="button" onClick={setPriceDetailModalIsOpen} className="btn btn-link text-primary p-0">details</button>)</td>
                                                <td className="font-weight-bold pl-3 text-right">{moneyFormatter.format(
                                                  context.state.propertyQuote?.promoDiscount?.tax?.lodgingTax + context.state.propertyQuote?.fees
                                                )}</td>
                                              </tr>
                                              <tr>
                                                <td>Total</td>
                                                <td className="font-weight-bold pl-3 text-right">{moneyFormatter.format(
                                                  context.state.propertyQuote?.promoDiscount?.baseRateDiscountApplied + context.state.propertyQuote?.promoDiscount?.tax?.lodgingTax + context.state.propertyQuote?.fees
                                                )}</td>
                                              </tr>


                                            </> : <>

                                              <tr>
                                                <td>Original Owner Rate</td>
                                                <td className="font-weight-bold pl-3 text-right">{moneyFormatter.format(
                                                  context.state.propertyQuote?.baseRate
                                                )}</td>
                                              </tr>


                                              <tr>
                                                <td>Taxes & Fees (<button type="button" onClick={setPriceDetailModalIsOpen} className="btn btn-link text-primary p-0">details</button>)</td>
                                                <td className="font-weight-bold pl-3 text-right">{moneyFormatter.format(
                                                  context.state.propertyQuote?.tax?.lodgingTax + context.state.propertyQuote?.fees
                                                )}</td>
                                              </tr>
                                              <tr>
                                                <td>Total</td>
                                                <td className="font-weight-bold pl-3 text-right">{moneyFormatter.format(
                                                  context.state.propertyQuote?.baseRate + context.state.propertyQuote?.tax?.lodgingTax + context.state.propertyQuote?.fees
                                                )}</td>
                                              </tr>
                                            </>}

                                          <tr>
                                            <td><b>Due now (50%)</b></td>
                                            <td className="font-weight-bold pl-3 text-right">{moneyFormatter.format(
                                              context.state.propertyQuote?.paymentRequests[0].amount
                                            )}</td>
                                          </tr>
                                          <tr>
                                            <td><b>Due {moment(context.state.propertyQuote?.paymentRequests[1].date).format('MM/DD/YYYY')} (50%)</b></td>
                                            <td className="font-weight-bold pl-3 text-right">{moneyFormatter.format(
                                              context.state.propertyQuote?.paymentRequests[1].amount
                                            )}</td>
                                          </tr>


                                        </table>


                                        {context.state.propertyQuote?.promoDiscount && <>
                                          {context.state?.propertyQuote.totalNights > 7 ? <>
                                            <div className="mt-3">Save <b>{context.state.propertyQuote?.promoDiscount.percent}%</b> off of the owner's rate (applies to first seven nights only)</div></> :
                                            <div className="mt-3">Includes <b>{context.state.propertyQuote?.promoDiscount.percent}%</b> discount on owner's rate of <b>{moneyFormatter.format(
                                              context.state.propertyQuote?.baseRate
                                            )}</b></div>
                                          }


                                        </>
                                        }

                                      </div>}

                                    </>
                                  }


                                  {
                                    !context.state.propertyQuote.prepaidDiscount && context.state.propertyQuote?.paymentRequests.length > 1 && <>
                                      <div className="mt-3">

                                        <div>

                                          <div className="border p-2 mt-3">
                                            <div><b>Transaction Detail</b></div>



                                            <table className="my-2">

                                              {context.state.propertyQuote?.promoDiscount ? <>

                                                <tr>
                                                  <td>Original Owner Rate</td>
                                                  <td className="font-weight-bold pl-3 text-right">{moneyFormatter.format(
                                                    context.state.propertyQuote?.baseRate
                                                  )}</td>
                                                </tr>
                                                <tr>
                                                  <td className="font-weight-bold  text-success"><b>Promotional Rate ({context.state.propertyQuote?.promoDiscount.percent}% Off)</b></td>
                                                  <td className="font-weight-bold text-success pl-3 text-right"><b>{moneyFormatter.format(
                                                    context.state.propertyQuote?.promoDiscount?.baseRateDiscountApplied
                                                  )}</b></td>
                                                </tr>
                                                <tr>
                                                  <td>Taxes & Fees (<button type="button" onClick={setPriceDetailModalIsOpen} className="btn btn-link text-primary p-0">details</button>)</td>
                                                  <td className="font-weight-bold pl-3 text-right">{moneyFormatter.format(
                                                    context.state.propertyQuote?.promoDiscount?.tax?.lodgingTax + context.state.propertyQuote?.fees
                                                  )}</td>
                                                </tr>
                                                <tr>
                                                  <td>Total</td>
                                                  <td className="font-weight-bold pl-3 text-right">{moneyFormatter.format(
                                                    context.state.propertyQuote?.promoDiscount?.baseRateDiscountApplied + context.state.propertyQuote?.promoDiscount?.tax?.lodgingTax + context.state.propertyQuote?.fees
                                                  )}</td>
                                                </tr>
                                              </> : <>
                                                <tr>
                                                  <td>Owner Rate</td>
                                                  <td className="font-weight-bold pl-3 text-right">{moneyFormatter.format(
                                                    context.state.propertyQuote?.baseRate
                                                  )}</td>
                                                </tr>
                                                <tr>
                                                  <td>Taxes & Fees</td>
                                                  <td className="font-weight-bold pl-3 text-right">{moneyFormatter.format(
                                                    context.state.propertyQuote?.tax?.lodgingTax + context.state.propertyQuote?.fees
                                                  )}</td>
                                                </tr>
                                                <tr>
                                                  <td>Total</td>
                                                  <td className="font-weight-bold pl-3 text-right">{moneyFormatter.format(
                                                    context.state.propertyQuote?.baseRate + context.state.propertyQuote?.tax?.lodgingTax + context.state.propertyQuote?.fees
                                                  )}</td>
                                                </tr>
                                              </>
                                              }


                                              <tr>
                                                <td>Due now (50%)</td>
                                                <td className="font-weight-bold pl-3 text-right">{moneyFormatter.format(
                                                  context.state.propertyQuote?.paymentRequests[0].amount
                                                )}</td>
                                              </tr>
                                              <tr>
                                                <td>Due {moment(context.state.propertyQuote?.paymentRequests[1].date).format('MM/DD/YYYY')} (50%)</td>
                                                <td className="font-weight-bold pl-3 text-right">{moneyFormatter.format(
                                                  context.state.propertyQuote?.paymentRequests[1].amount
                                                )}</td>
                                              </tr>
                                            </table>

                                            {context.state.propertyQuote?.promoDiscount && <>
                                              {context.state?.propertyQuote.totalNights > 7 ? <>
                                                <div className="mt-3">Save <b>{context.state.propertyQuote?.promoDiscount.percent}%</b> off of the owner's rate (applies to first seven nights only)</div></> :
                                                <div className="mt-3">Includes <b>{context.state.propertyQuote?.promoDiscount.percent}%</b> discount on owner's rate of <b>{moneyFormatter.format(
                                                  context.state.propertyQuote?.baseRate
                                                )}</b></div>
                                              }</>}

                                          </div>
                                        </div>
                                      </div>
                                    </>}



                                </div>
                              </div >

                              <div className="form-group">
                                <div className="border-bottom mt-4 mb-3 pb-2">
                                  <h2 className="m-0 d-flex">
                                    <div className={styles.chevron}>4</div>{' '}

                                    Review your pending reservation

                                  </h2>
                                </div>
                                {context.state?.propertyQuote?.prepaidDiscount && context.state.prepaidDiscount && <>
                                  <p>
                                    <b>
                                      {context.state?.propertyQuote?.prepaidDiscount && context.state.prepaidDiscount == 'NO' && <>{moneyFormatter.format(
                                        getFinalPrice({ propertyQuote: context.state?.propertyQuote, prepaidDiscountRequested: false })
                                      )}</>}
                                      {context.state?.propertyQuote?.prepaidDiscount && context.state.prepaidDiscount == 'YES' && <>{moneyFormatter.format(getFinalPrice({ propertyQuote: context.state?.propertyQuote, prepaidDiscountRequested: true }))}</>}
                                    </b>{' '}
                                    will be charged to your account
                                  </p>
                                </>}
                                {!context.state?.propertyQuote?.prepaidDiscount && <>
                                  <p>
                                    <b>
                                      <>{moneyFormatter.format(
                                        getFinalPrice({ propertyQuote: context.state?.propertyQuote, prepaidDiscountRequested: false })
                                      )}</>
                                    </b>{' '}
                                    will be charged to your account
                                  </p>
                                </>}
                              </div >
                            </>
                          ) : null;
                        }}
                      </PropertyQuoteConsumer >
                      {/* submitCount = {submitCount} <br />
                      touched = {Object.keys(touched)}
                      errors = {Object.keys(errors)} */}
                      {
                        values.emailAddress != '' && !errors.emailAddress ? (
                          <>
                            {console.log('search', search)}
                            <PropertyQuoteConsumer>
                              {context => {
                                return <PayPalScriptProvider
                                  options={{
                                    clientId:
                                      sandbox == 'true' ? 'AQykNEwVR5Ugd7H3UHBv0lvT6HrENTa0xL5UIR60i__f3Y70QoPXt1a6MMiqiBtmZO0-IOP0cYzTJg0Q' :
                                        'AUtw8PMfTIs_WdSHa-9fyf0xYpk4xpm-_3ZTfk2Vg2ZxQTurrCa7wJEbwDSTNRm-zzbbdz3tJjTnNz_Z',
                                    //
                                    components: 'buttons',
                                    currency: 'USD',
                                  }}
                                >
                                  <div
                                    style={{
                                      position: 'absolute',
                                      zIndex: '-1',
                                      height: '40px',
                                    }}
                                  >
                                    <Dots />
                                  </div>
                                  <ButtonWrapper
                                    promo={promo}
                                    showSpinner={false}
                                    values={values}
                                    context={context}
                                    errors={errors}
                                    search={search}
                                  />
                                </PayPalScriptProvider>
                              }}
                            </PropertyQuoteConsumer>
                          </>
                        ) : (
                          <button
                            className="btn btn-secondary text-primary mb-2 font-weight-bold"
                            onClick={() => {
                              const reserveFormEl = document.getElementById(
                                'reserveForm'
                              );
                              //  alert(reserveFormEl.checkValidity());
                              handleSubmit();
                              console.log('numberOfAdults', numberOfAdults);
                              setFormIsValid(reserveFormEl.checkValidity());
                              document
                                .getElementById('top-paragraph')
                                .scrollIntoView({
                                  behavior: 'smooth',
                                });

                              // if (numberOfAdults > 0) {
                              //   setFormIsValid(reserveFormEl.checkValidity());
                              // } else {
                              //   document
                              //     .getElementById('guest-count')
                              //     .scrollIntoView({
                              //       behavior: 'smooth',
                              //     });
                              // }
                              //
                            }}
                            type="submit"
                          >
                            Continue to Payment
                          </button>
                        )
                      }
                      {/* <button type="submit" className="btn btn-primary mb-4">
                        Continue to Paypal
                      </button> */}
                    </form>
                  );
                }}
              </Formik>
              {/* <div>{moneyFormatter.format(propertyQuote.rate)}</div> */}
              <div className="form-group pt-2">
                <b>House Rules</b>
                <ul className="mt-2">
                  <li>Minimum age to rent is 35.</li>
                  <li>
                    All events must be approved in advance prior to making
                    initial reservation.
                  </li>
                  <li>
                    Pets allowed: Up to 3 cats or dogs of any size per home.

                  </li>
                  <li>
                    Because little hands touch floors, please leave your shoes
                    at the door.
                  </li>
                  <li>
                    After fun in ocean/river, please use the outdoor showers to
                    rinse off sand
                    {(property.amenities || []).find(amenity => amenity.key == 'pool' || amenity.key == 'hottub') ? <span> before enjoying the hot tub or pool (pets, too!).</span> : <span>.</span>}
                    {console.log('property3', property)}
                  </li>
                </ul>
              </div>
              <div className="form-group mt-3">
                <b>IMPORTANT LEGAL NOTICE (LIABILITY)</b>

                <div className="my-2">
                  Your reservation with Beach Houses in Paradise is subject to
                  the attached agreement, which LIMITS OUR LIABILITY:
                </div>

                <div className="my-2">PLEASE READ CAREFULLY:</div>

                <div className="my-2">
                  When you rent a Beach House in Paradise, the home becomes
                  ~your~ home for the duration of your stay . . . your home away
                  from home (or your "Vacation Home"). Just as when you bring
                  guests into your own home, you agree to accept responsibility
                  for the safety and welfare of anyone you bring or invite into
                  your Vacation Home. This includes -- but is not limited to --
                  minors, seniors, pets, and anyone in your group with limited
                  motion, vision, hearing, health or other ailments. This means
                  that you agree to indemnify and hold harmless Beach Houses in
                  Paradise (and all individuals and entities connected to, and
                  involved with, the administration and operation of your
                  Vacation Home) from all liability and claims that may arise
                  from the use of the home by you and your guests. For
                  additional details, please see the terms & conditions attached
                  to this payment invoice.
                </div>
                <a
                  href="https://images.beachhousesinparadise.com/uploads/docs/lease-agreement-v2019-02-18.pdf"
                  target="_blank"
                >
                  Lease Agreement
                </a>
              </div>
            </div>

            <div className="col col-12  col-md-5 col-xl-4">
              <aside>
                <PropertyQuoteConsumer>
                  {context => {
                    return (<QuotePanel
                      search={search}
                      property={property}
                      location={location}
                      numberOfPets={numberOfPets}
                      guestNumbersLabel={guestNumbersLabel}
                      propertyId={property.id}
                      promo={promo}
                      priceDetailModalIsOpen={priceDetailModalIsOpen}
                      prepaidDiscountRequested={context.state?.prepaidDiscount}
                      onPriceDetailClick={(openOrClose) => {
                        setPriceDetailModalIsOpen(openOrClose)
                      }}
                    />)
                  }}</PropertyQuoteConsumer>
                <div>
                  <b>What Guests are Saying</b>
                  <p className="my-2">For over 20 years, we've delivered amazing vacation experiences to discerning &amp; knowledgeable travelers</p>

                  <Testimonials />
                </div>
              </aside>
            </div>
          </div>
        </PropertyQuoteProvider>

      </div >

    </Layout >
  );
};
export const query = graphql`
  query ReserveDates($slug: String, $propertyForeignKey: Int) {
    bhip {
      sitePage(where: { slug: $slug }) {
        ...SitePageData
      }
      property(where: { id: $propertyForeignKey }) {
        id
        additionalMessaging
        priceOffSeason
        label
        key
        name
        description
        sleeps
        bedrooms
        bathrooms
        type
        latitude
        longitude
        group
        streetAddress1
        amenities {
          key
        }
        sitePage {
          slug
          status
          featuredImageCollection {
            files {
              tiny: version(where: { key: "tiny" }) {
                fullUrl
              }
              medium: version(where: { key: "medium" }) {
                fullUrl
              }
            }
          }
        }
      }
    }
  }
`;

export default WithLocation(ReserveDatesPage);
